export const primaryColors =[
    {className:"color-1"},
    {className:"color-2"},
    {className:"color-3"},
    {className:"color-4"},
    {className:"color-5"},
    {className:"color-6"}
]

export const backgroundColors =[
    {className:"bg-1"},
    {className:"bg-2"}
]